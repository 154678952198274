import revive_payload_client_Cqny6r6U8G from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ALKOqWFr8g from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_heC8BlIAlz from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YPo703PRcZ from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RpzRJT1iOo from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IM6iy9xSPc from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sglfEprdDd from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_901WyBJkZt from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/components.plugin.mjs";
import prefetch_client_j1fCk5pP9R from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_lrqyodvoddexrqgyg6h4yjjdpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_QURhys4JuU from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_GOVRSZOoAR from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import templates_plugin_f9fb4cba_6DASZPzKlH from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/templates.plugin.f9fb4cba.mts";
import wow_RYu4ocOzj5 from "/home/runner/work/hopeai-official/hopeai-official/plugins/wow.ts";
export default [
  revive_payload_client_Cqny6r6U8G,
  unhead_ALKOqWFr8g,
  router_heC8BlIAlz,
  payload_client_YPo703PRcZ,
  navigation_repaint_client_RpzRJT1iOo,
  check_outdated_build_client_IM6iy9xSPc,
  chunk_reload_client_sglfEprdDd,
  plugin_vue3_901WyBJkZt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_j1fCk5pP9R,
  plugin_client_QURhys4JuU,
  plugin_GOVRSZOoAR,
  templates_plugin_f9fb4cba_6DASZPzKlH,
  wow_RYu4ocOzj5
]